import React from 'react';

import { getQueryStringParams } from '../../utils/utils';

export const HeaderLogo: React.FC<any> = () => {
  const { partnerStylingOverrides } = window.tz_globalConfigs;
  const { logoUrl } = window.tz_globalConfigs.styling;
  const urlParams: any = getQueryStringParams(location.search);
  return (
    <a
      href={
        urlParams.initialUrl
        || urlParams.origin
        || `${window.tz_globalConfigs?.customPrefixUrl || ''}/`
      }
    >
      <img
        className="logo-image"
        src={partnerStylingOverrides?.['whitelabel.logoUrl'] || logoUrl}
        alt=""
      />
    </a>
  );
};
