import './widget.scss';

import { getDateDiff } from '@transferz/utils';
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSessionStorage } from '../../hooks/sessionStorage/useSessionStorage';
import { useFetchHubByAddress } from '../../hooks/useFetchHubByAddress';
import { useLocalize } from '../../hooks/useLocalize';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useResolveGoogleAddress } from '../../hooks/useResolveGoogleAddress';
import { getQueryStringParams, setHtmlTextDirection } from '../../utils/utils';
import { DayPicker, TimePicker } from '../dateTimePicker/DateTimePicker';
import { LanguageContext } from '../localization/LanguageContext';
import { LuggageInformation } from '../luggageWidget/LuggageOptions';
import { LuggageWidget } from '../luggageWidget/LuggageWidget';
import { PassengersWidget } from '../passengersWidget/PassengersWidget';
import { AddressSpec, MultilingualHubWithPlaceId } from '../search/input/utils';
import { Search } from '../search/Search';
import { ReactComponent as SearchIcon } from './search.svg';

export const PLACE_TYPES = [
  'establishment',
  'intersection',
  'route',
  'street_address',
  'street_number',
  'premise',
  'point_of_interest',
  'landmark',
];

interface PassengersQuantityInfo {
  adultPassengers: number,
  childPassengers: number,
  infantPassengers: number
}

interface WidgetProps {
  showTextOnTop?: boolean;
  variant?: 'default' | 'condensed';
  usePartnerStyles?: boolean;
}

function tryParseJSON(possibleJSON: any, _default: any = {}): any {
  try {
    return JSON.parse(possibleJSON);
  } catch (e) {
    return _default;
  }
}

export const Widget: React.FC<WidgetProps> = ({ showTextOnTop = true, variant = 'default', usePartnerStyles = true }) => {
  const { setLocale } = useContext(LanguageContext);
  const navigate = useNavigate();
  const { translate: t, locale } = useLocalize();
  const [storedValue, setSessionValue] = useSessionStorage('widgetData');
  const [, setInitialUrl] = useSessionStorage('initialUrl');
  const getHubByAddress = useFetchHubByAddress(locale);
  const date = new Date();
  const plusTwoDays = date.setDate(date.getDate() + 2);
  const plusNineDays = date.setDate(date.getDate() + 7);
  const isEmbedded = window.location.href.includes('/start');

  const [roundTrip, setRoundTrip] = useState(false);

  const [isOutbound, setIsOutbound] = useState(true);
  const [passengers, setPassengers] = useState<PassengersQuantityInfo>({ adultPassengers: 2, childPassengers: 0, infantPassengers: 0 });
  const [luggage, setLuggage] = useState<LuggageInformation>({ checkedLuggage: 2, carryOnLuggage: 0 });
  const [day, setDay] = useState(plusTwoDays) as any;
  const [time, setTime] = useState(new Date(0, 0, 0, 12, 0)) as any;
  const [dayRound, setDayRound] = useState(plusNineDays) as any;
  const [timeRound, setTimeRound] = useState(new Date(0, 0, 0, 12, 0)) as any;
  const [errors, setErrors] = useState([]) as any;
  const [placeId, setPlaceId] = useState('');
  const [openResultsInsideIframe, setOpenResultsInsideIframe] = useState(false);
  const [, setValue] = useLocalStorage('preferredLanguage');

  const [address, setAddress] = useState<AddressSpec | undefined>(undefined);
  const [hub, setHub] = useState<MultilingualHubWithPlaceId | undefined>(undefined);
  const buttonRef = useRef(null) as any;

  const roundContainerRef = useRef<HTMLDivElement>(null);
  const [roundContainerHeight, setRoundContainerHeight] = useState(0);
  useLayoutEffect(() => {
    setRoundContainerHeight(roundContainerRef.current?.scrollHeight || 0);
  }, [roundTrip]);

  const resolveGoogleAddress = useResolveGoogleAddress();

  const setAddresses = async (hubAddress: string, address: string) => {
    if (address) {
      const googleAddress = await resolveGoogleAddress(address);
      setAddress({
        address: googleAddress.description,
      });
    }

    if (hubAddress) {
      const hub = await getHubByAddress(hubAddress);
      if (hub) {
        setHub(hub);
      }
    }
  };

  useEffect(() => {
    let params = {} as any;
    let initialUrl;

    if (storedValue) {
      if (storedValue.destination) {
        const parsedDestination = typeof storedValue.destination === 'object'
          ? storedValue.destination
          : JSON.parse(storedValue.destination);

        storedValue.destination = {
          address: decodeURIComponent(parsedDestination.address),
          placeId: parsedDestination.placeId,
          addressHubInformation: parsedDestination.addressHubInformation,
        };
      }
      params = storedValue;
    }
    if (window.location.search) {
      const urlParams = getQueryStringParams(window.location.search);
      initialUrl = urlParams.initialUrl;
      params = {
        ...params,
        ...urlParams,
      };
      if (params.destination) {
        params.destination =  typeof params.destination === 'object'
          ? params.destination
          : tryParseJSON(params.destination, {});
      }
      params.destination = { placeId: params.placeId, ...params.destination };
    }
    setWidgetData(params);

    /**
     * we need to save the url from where the user came to be able to redirect back to the first page
     * when the user clicks 'edit' from the address details section.
     * in case when an application opens in an iframe the parent url should be saved.
     * iframe should get initialUrl from parent application.
     */
    if (!initialUrl) {
      if (window.location.href.indexOf('/start') > -1) {
        initialUrl = document.referrer || window.location.href.replace('/start', '');
      } else {
        initialUrl = window.location.href;
      }
    }
    setInitialUrl(initialUrl);
    // eslint-disable-next-line
  }, []);

  const params = getQueryStringParams(window.location.search);
  useEffect(() => {
    setTimeout(() => {
      if (params?.autoSubmit === 'true') {
        buttonRef.current.click();
      }
    }, 2000);
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = window.tz_globalConfigs.partnerStylingOverrides?.['whitelabel.page.backgroundColor'] || window.tz_globalConfigs.styling.backgroundColor || 'white';
    // eslint-disable-next-line
  }, []);

  const setWidgetData = async (params: any) => {
    const [adultPassengers, childPassengers, infantPassengers] = [
      Number(params?.adultPassengers),
      Number(params?.childPassengers),
      Number(params?.infantPassengers),
    ];

    if (adultPassengers || childPassengers || infantPassengers) {
      setPassengers({ ...passengers, adultPassengers: adultPassengers, childPassengers: childPassengers, infantPassengers: infantPassengers });
    }
    params?.carryOnLuggage && setLuggage({
      carryOnLuggage: Number(params.carryOnLuggage ?? luggage.carryOnLuggage),
      checkedLuggage: Number(params.checkedLuggage ?? luggage.checkedLuggage),
    });

    setRoundTrip(!!params?.directionality?.includes('ROUNDTRIP'));
    setIsOutbound(!params?.directionality?.includes('INBOUND'));

    if (params?.directionality?.includes('INBOUND')) {
      if (params?.inboundPickup && params.inboundPickup !== 'null') {
        setDay(new Date(params.inboundPickup));
        setTime(new Date(params.inboundPickup));
      }
      if (params?.outboundPickup && params.outboundPickup !== 'null') {
        setDayRound(new Date(params.outboundPickup));
        setTimeRound(new Date(params.outboundPickup));
      }
    } else {
      if (params?.inboundPickup && params.inboundPickup !== 'null') {
        setDayRound(new Date(params.inboundPickup));
        setTimeRound(new Date(params.inboundPickup));
      }
      if (params?.outboundPickup && params.outboundPickup !== 'null') {
        setDay(new Date(params.outboundPickup));
        setTime(new Date(params.outboundPickup));
      }
    }
    if (params?.pickupTime && params.pickupTime !== 'null') {
      setDay(new Date(params.pickupTime));
      setTime(new Date(params.pickupTime));
    }
    if (params?.destination?.address && params?.hubName) {
      let placeId = params?.destination?.placeId || params?.placeId;
      if (!placeId) {
        const googleAddress = await resolveGoogleAddress(params.destination.address);
        placeId = googleAddress.place_id;
      }
      setAddress({
        address: params.destination.address,
        placeId: placeId,
      });
      const hubInfo = await getHubByAddress(params.hubName);
      setHub(hubInfo);
    }
    if (params?.pickupAddress || params?.dropoffAddress) {
      if (isOutbound) {
        setAddresses(params.pickupAddress, params.dropoffAddress);
      } else {
        setAddresses(params.dropoffAddress, params.pickupAddress);
      }
    }
    if (params?.placeId) {
      setPlaceId(params?.placeId);
    }
    if (params?.redirectWithinCurrentFrame) {
      setOpenResultsInsideIframe(true);
    }
    if (params?.language) {
      const language = params.language === 'pt-PT' ? 'pt-BR' : params.language;
      // Known issue: without setTimeout only widget will be translated.
      setTimeout(() => {
        setValue(language);
        setLocale?.(language);
        setHtmlTextDirection(language);
      });
    }
  };

  const validateForm = () => {
    const formErrors: any = [];
    if (!address?.address) {
      formErrors.push({
        name: 'address',
        message: 'Address is required',
      });
    }
    if (!hub) {
      formErrors.push({
        name: 'hub',
        message: 'Hub is required',
      });
    }
    if (!address?.address || !hub) {
      setErrors(formErrors);
      return false;
    }
    return true;
  };

  const getDirectionality = () => {
    let directionality = '';
    if (roundTrip) {
      directionality = isOutbound ? 'ROUNDTRIP_OUTBOUND' : 'ROUNDTRIP_INBOUND';
    } else {
      directionality = isOutbound ? 'OUTBOUND' : 'INBOUND';
    }
    return directionality;
  };

  const getISOFormattedDateWithTimeZone = (date: string) => {
    const newDate = new Date(date);
    return new Date(newDate.getTime() - (newDate.getTimezoneOffset() * 60000)).toISOString();
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const isFormValid = validateForm();
    if (isFormValid) {
      const inboundPickupDay = getISOFormattedDateWithTimeZone(day).split('T')[0];
      const inboundPickupTime = new Date(time).toTimeString()
        .split(' ')[0].split(':').slice(0, 2)
        .join(':');
      const outboundPickupDay = getISOFormattedDateWithTimeZone(dayRound).split('T')[0];
      const outboundPickupTime = new Date(timeRound).toTimeString()
        .split(' ')[0].split(':').slice(0, 2)
        .join(':');
      const queryParams: any = {
        partnerId: window.tz_globalConfigs?.partnerId,
        hubId: hub?.id,
        hubPlaceId: hub?.placeId,
        hubName: hub?.autoCompleteName,
        hubNameSecondary: hub?.autoCompleteLocation,
        hubLat: hub?.position?.lat,
        hubLng: hub?.position?.lng,
        hubType: hub?.hubType,
        directionality: getDirectionality(),
        adultPassengers: passengers.adultPassengers,
        childPassengers: passengers.childPassengers,
        infantPassengers: passengers.infantPassengers,
        destination: JSON.stringify(address),
        luggage: Math.ceil((luggage.carryOnLuggage / 2) + luggage.checkedLuggage),
        checkedLuggage: luggage.checkedLuggage,
        carryOnLuggage: luggage.carryOnLuggage,
        inboundPickup: isOutbound ? `${outboundPickupDay}T${outboundPickupTime}:00` : `${inboundPickupDay}T${inboundPickupTime}:00`,
        outboundPickup: isOutbound ? `${inboundPickupDay}T${inboundPickupTime}:00` : `${outboundPickupDay}T${outboundPickupTime}:00`,
        isRoundTrip: roundTrip,
        placeId: placeId,
        countryCode: hub?.countryCode,
      };

      let url = `${isEmbedded ? window.tz_globalConfigs.primaryUrl : ''}${window.tz_globalConfigs?.customPrefixUrl || ''}/booking/vehicle-selection`;
      const params: any = { ...getQueryStringParams(window.location.search), ...queryParams };

      Object.keys(params).forEach((key: any) => {
        url += `${url.includes('?') ? '&' : '?'}${key}=${encodeURIComponent(params[key])}`;
      });
      const trackData = getTrackEventData(inboundPickupDay, outboundPickupDay, inboundPickupTime, outboundPickupTime);
      url += `&widgetTrackData=${encodeURIComponent(JSON.stringify(trackData))}`;

      if (isEmbedded) {
        if (openResultsInsideIframe) {
          window.open(url, '_self');
        } else {
          window.open(url, params?.newWindow === 'true' ? '_blank' : '_parent');
        }
      } else {
        navigate(url);
      }

      setWidgetDataInSessionStorage(queryParams);
    } else {
      console.log('Form validation fail');
    }
  };

  const setWidgetDataInSessionStorage = (queryParams: any) => {
    setSessionValue(queryParams);
  };

  const getTrackEventData = (inbound: string, outbound: string, inboundTime: string, outboundTime: string) => {
    return {
      event: 'show_possibilities',
      app_platform: 'web',
      page_layout: window.matchMedia('(min-width: 1241px)').matches ? 'desktop' : 'mobile',
      environment: window.tz_globalConfigs?.environmentConfigs.environment,
      language: locale || 'nl-NL',
      website_id: window.tz_globalConfigs?.name,
      widget_to: isOutbound
        ? address?.address
        : `${hub?.autoCompleteName} ${hub?.countryCode}`,
      widget_from: isOutbound
        ? `${hub?.autoCompleteName} ${hub?.countryCode}`
        : address?.address,
      widget_travel_date: inbound,
      widget_travel_date_return: roundTrip ? outbound : '',
      widget_amount_adults: passengers.adultPassengers,
      widget_amount_children: passengers.childPassengers,
      widget_amount_infants: passengers.infantPassengers,
      widget_days_from_now: getDateDiff(isOutbound ? outbound : inbound),
      widget_amount_suitcases: luggage,
      widget_roundtrip: roundTrip ? 'yes' : 'no',
      widget_hub_id: hub?.id,
      widget_directionality: isOutbound ? 'outbound' : 'inbound',
      widget_travel_time: inboundTime,
      widget_travel_time_return: roundTrip ? outboundTime : '',
    };
  };

  const clearError = (name: string | string[]) => {
    const names = Array.isArray(name) ? name : [name];
    const otherErrors = errors.filter((error: any) => !names.includes(error.name));
    setErrors(otherErrors);
  };

  const customWidgetStyles = usePartnerStyles ? {
    '--widget-background-color': window.tz_globalConfigs?.partnerStylingOverrides?.['whitelabel.widget.backgroundColor'] || window.tz_globalConfigs?.styling?.primaryColor || '#fc3',
    '--label-text-color': window.tz_globalConfigs?.styling?.primaryContrastColor || '#3c4b64',
    '--whitelabel-widget-text-color': window.tz_globalConfigs?.partnerStylingOverrides?.['whitelabel.widget.textColor'],
    '--whitelabel-forms-label-text-color': window.tz_globalConfigs?.partnerStylingOverrides?.['whitelabel.forms.labelTextColor'],
    '--form-field-text-color': window.tz_globalConfigs?.styling?.backgroundContrastColor,
    '--primary-button-color': window.tz_globalConfigs?.partnerStylingOverrides?.['whitelabel.widget.buttonColor'] || window.tz_globalConfigs?.styling?.secondaryColor || '#48a947',
    '--primary-button-text-color': window.tz_globalConfigs?.partnerStylingOverrides?.['whitelabel.widget.buttonTextColor'] || window.tz_globalConfigs?.styling?.secondaryContrastColor || '#fff',
  } as React.CSSProperties : {
    '--widget-background-color': '#F7F8F8',
    '--primary-button-color': '#0083A8',
    '--primary-button-text-color': 'white',
  } as React.CSSProperties;

  const getMinutesAfterTime = (time: Date) => {
    return new Date(time).setMinutes(new Date(time).getMinutes() + 1);
  };

  const setReturnDate = (day: Date) => {
    if (dayRound < day) {
      let resultDate = new Date(day);
      resultDate.setDate(resultDate.getDate() + 7);
      setDayRound(resultDate);
    } else {
      setDayRound(dayRound);
    }
  };

  useEffect(() => {
    if (dayRound < day) {
      let resultDate = new Date(day);
      resultDate.setDate(resultDate.getDate() + 7);
      setDayRound(resultDate);
    }
  }, [day]);

  const setReturnTime = (day: Date, time: Date, dayRound: Date) => {
    if (new Date(dayRound).toDateString() === new Date(day).toDateString()) {
      if (new Date(timeRound).getHours() < new Date(time).getHours()) {
        setTimeRound(getMinutesAfterTime(time));
      } else if (new Date(timeRound).getHours() === new Date(time).getHours()) {
        setTimeRound(new Date(timeRound).getMinutes() <= new Date(time).getMinutes()
          ? getMinutesAfterTime(time)
          : timeRound);
      }
    }
  };

  const handleAddressChange = (addressSpec: AddressSpec | undefined) => {
    setAddress(addressSpec);
    setPlaceId(addressSpec?.placeId ?? '');
  };

  return (
    <div className={`widget font-regular variant-${variant} ${showTextOnTop ? '' : 'condensed'}`} style={customWidgetStyles}>
      { showTextOnTop && <h2 className="font-bold widget-title">{t('transferWorldwide')}</h2> }
      <form noValidate>
        <Search
          address={address}
          hub={hub}
          onAddressChange={handleAddressChange}
          onHubChange={setHub}
          setIsOutbound={setIsOutbound}
          isOutbound={isOutbound}
          clearError={clearError}
          errors={errors}
          styles={{
            formFieldTextColor: window.tz_globalConfigs?.styling?.backgroundContrastColor,
          }}
        />
        <div className="widget-details">
          <div className="widget-details-date">
            <div className="widget-details-date-picker">
              <DayPicker
                id="date-id"
                value={day}
                onChange={(value: any) => [setDay(value), setReturnDate(value), setReturnTime(value, time, dayRound)]}
                round={false}
                locale={locale}
              />
            </div>
            <div className="widget-details-time-picker">
              <TimePicker
                id="time-id"
                value={time}
                onChange={(value: any) => [setTime(value), setReturnTime(day, value, dayRound)]}
                round={false}
              />
            </div>
          </div>
          <div className="widget-details-info">
            <PassengersWidget passengers={passengers} setPassengers={setPassengers} />
            <LuggageWidget setLuggage={setLuggage} luggage={luggage} />
          </div>
          <div className="widget-details-action">
            <label htmlFor="round" className="roundtrip-control">
              <input type="checkbox" className="checkbox-2" checked={roundTrip} id="round" onChange={() => {
                setRoundTrip(!roundTrip);
              }} />
              <span>{t('roundTrip')}</span>
            </label>
          </div>
          {variant === 'condensed' && <div className="widget-details-button widget-details-button-condensed">
            <button ref={buttonRef} onClick={handleSubmit}>
              <SearchIcon />
            </button>
          </div>}
        </div>
        <div
          className={`widget-details ${roundTrip ? 'widget-details-round widget-details-round-expanded' : 'widget-details-round-collapsed'}`}
          style={{ '--round-expanded-height': `${roundContainerHeight}px` } as React.CSSProperties}
        >
          {roundTrip && (
            <div ref={roundContainerRef} className="widget-details-date">
              <div className="widget-details-date-picker">
                <span>{t('pickupDate')}</span>
                <DayPicker
                  id="round-trip-date-id"
                  value={dayRound}
                  onChange={(value: any) => [setDayRound(value), setReturnTime(day, time, value)]}
                  round={true}
                  locale={locale}
                  minDate={day}
                />
              </div>
              <div className="widget-details-time-picker">
                <span>{t('pickupTime')}</span>
                <TimePicker
                  id="round-trip-time-id"
                  value={timeRound}
                  onChange={(value: any) => setTimeRound(value)}
                  round={true}
                  minTime={new Date(dayRound).toDateString() === new Date(day).toDateString() ? getMinutesAfterTime(time) : null}
                />
              </div>
            </div>
          )}
          {variant === 'default' && <div className="widget-details-button">
            <button ref={buttonRef} onClick={handleSubmit}>
              <SearchIcon />
              {t('showPossibilities')}
            </button>
          </div>}
        </div>
      </form>
    </div>
  );
};

