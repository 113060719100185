import './contactInformation.scss';

import { CButton } from '@coreui/react';
import { Loading } from '@transferz/components/loading';
import { getCurrencyInfo } from '@transferz/utils';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSessionStorage } from '../../hooks/sessionStorage/useSessionStorage';
import { useBookingContext } from '../../hooks/useBookingContext';
import { useDataLayer } from '../../hooks/useDataLayer';
import { useLocalize } from '../../hooks/useLocalize';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { getQueryStringParams } from '../../utils/utils';
import { ContactInformationForm } from './ContactInformationForm';

export const ContactInformation: React.FC<any> = () => {
  const { isLoading, isBookingLoading, queryData, createBooking, error, getQuotesFromQuery } = useBookingContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const flags = useFlags();
  const { translate: t } = useLocalize();
  const navigate = useNavigate();
  const [storedValue, setSessionValue] = useSessionStorage('formData');
  const [preferredLanguage] = useLocalStorage('preferredLanguage');
  const { trackEvent } = useDataLayer();
  const [formError, setFormError] = useState(null) as any;

  const { partnerStylingOverrides } = window.tz_globalConfigs;
  const parnerUsesSingleCheckout = window.tz_globalConfigs.applicationSettings?.['onlineBookingTool.singleCheckout.enabled'] === 'true';

  getQuotesFromQuery();

  const onSubmit = async (formData: any) => {
    handleTrackEvent(formData);

    try {
      setIsSubmitting(true);
      const params: any = getQueryStringParams(window.location.search);
      const formDataWithReference = { ...formData, partnerReference: params?.partnerReference };
      const booking = await createBooking(formDataWithReference);
      setSessionValue(formDataWithReference);
      if (booking.code && booking.totalPrice) {
        const totalPrice = booking.currencyCode ? `${getCurrencyInfo(booking.currencyCode).symbol} ${booking.totalPrice}` : booking.totalPrice;
        if (flags.singleChekoutDomain && parnerUsesSingleCheckout) {
          const checkoutUrl = window.environmentConfigs?.checkoutWhitelabelUrl || window.location.origin;
          const origin = `${window.location.origin}${window.tz_globalConfigs?.customPrefixUrl || ''}`;
          const partner = window.tz_globalConfigs?.name;
          window.location.href = `${checkoutUrl}/booking/payment/${booking.code}/${booking.id}/${booking.totalPrice}/${booking.currencyCode}${window.location.search}&partner=${partner}&origin=${encodeURIComponent(origin)}&totalPrice=${totalPrice}&countryCode=${formData.country.value}&countryName=${formData.country.label}`;
        } else {
          navigate({
            pathname: `${window.tz_globalConfigs?.customPrefixUrl || ''}/booking/payment/${booking.code}/${booking.id}/${booking.totalPrice}/${booking.currencyCode}${window.location.search}&totalPrice=${totalPrice}&countryCode=${formData.country.value}&countryName=${formData.country.label}`,
          });
        }
      }
    } catch (e) {
      setFormError(e);

      /*
       * we used the catch block instead of finally intentionally
       * to prevent the loading spinner from disappearing while window.location.href is being executed
       * since it has a delay.
       */
      setIsSubmitting(false);
    }
  };

  const digestMessage = async (text: string) => {
    const msgUint8 = new TextEncoder().encode(text);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  };

  const handleTrackEvent = async (formData: any) => {
    const {
      bookerDetails: {
        email,
        firstName,
        lastName,
      },
      inboundFlightNumber = '',
      outboundFlightNumber = '',
    } = formData;
    const lastnameSHA = await digestMessage(lastName);
    const emailSHA = await digestMessage(email);
    trackEvent({
      event: 'checkout_register',
      app_platform: 'web',
      page_layout: window.matchMedia('(min-width: 1241px)').matches ? 'desktop' : 'mobile',
      environment: window.tz_globalConfigs?.environmentConfigs.environment,
      language: preferredLanguage || 'nl-NL',
      website_id: window.tz_globalConfigs?.name,
      checkout_first_name: firstName,
      checkout_last_name_sha256: lastnameSHA,
      checkout_email_sha256: emailSHA,
      checkout_flight_number: outboundFlightNumber || null,
      checkout_flight_number_return: inboundFlightNumber || null,
    });
  };

  return (
    <>
      <h3 style={{ color: partnerStylingOverrides?.['whitelabel.page.textColor'] || window.tz_globalConfigs?.styling?.backgroundContrastColor || '#51536d' }} className="page-title title-black">{t('content.register')}</h3>
      {error && <div className="no-price">{t(error)}</div>}
      <div className="contact-information">
        {(isLoading || isBookingLoading || isSubmitting) && (
          <div className="loading-component">
            <div className="loading-animation" style={{ color: window.tz_globalConfigs?.styling?.backgroundContrastColor || '#fc3' }}>
              <Loading />
            </div>
          </div>
        )}
        <ContactInformationForm
          data={storedValue || {}}
          isRoundTrip={queryData.isRoundTrip === 'true'}
          onSubmit={onSubmit}
          error={formError}
        />
        {!flags.dataPreselected && (
          <CButton
            form="registerForm"
            color="primary"
            className="btn text-uppercase register-submit mb-2"
            type="submit"
            style={{ backgroundColor: window.tz_globalConfigs?.styling?.secondaryColor || '#48a947', color: window.tz_globalConfigs?.styling?.secondaryContrastColor || '#fff' }}>
            {t('register.bookNow')}
          </CButton>
        )}
      </div>
    </>
  );
};

export default ContactInformation;
