import { useState } from 'react';

import { useQueryGetQuotes } from '../queries/quote/hooks/useQueryGetQuotes';
import { getQueryStringParams } from '../utils/utils';
import { useFetchHubByAddress } from './useFetchHubByAddress';
import { useLocalize } from './useLocalize';

export const useRetrieveQuotes = () => {
  const { translate: t, locale } = useLocalize();
  const [isLoading, setIsLoading] = useState(false);
  const [quotes, setQuotes] = useState([]) as any;
  const { refetch: getQuotes } = useQueryGetQuotes({
    variables: {
      params: {},
    },
    skip: true,
  });
  const fetchHubByAddress = useFetchHubByAddress(locale);
  const [error, setError] = useState('');

  const getQueryData = async (sessionData?: any): Promise<any> => {
    const data: any = sessionData || getQueryStringParams(window.location.search);
    if (!data.hubId && data.hubName) {
      const hub = await fetchHubByAddress(data.hubName);
      data.hubId = hub?.id;
    }
    const partnerId = Number(data.partnerId) || window.tz_globalConfigs?.partnerId;
    return {
      partnerId: partnerId,
      hub: Number(data.hubId),
      directionality: data.directionality,
      destination: {
        address: data.destination?.address ? data.destination.address : JSON.parse(data.destination)?.address,
      },
      isRoundTrip: data.isRoundTrip === 'true',
      ...(!data.adultPassengers ? { passengers: Number(data.passengers) } : { adultPassengerCount: Number(data.adultPassengers) }),
      ...(data.childPassengers && { childPassengerCount: Number(data.childPassengers) }),
      ...(data.infantPassengers && { infantPassengerCount: Number(data.infantPassengers) }),
      luggage: Number(data.luggage),
      inboundPickup: data.inboundPickup === 'null' ? '' : data.inboundPickup,
      outboundPickup: data.outboundPickup === 'null' ? '' : data.outboundPickup,
    };
  };

  const retrieveQuotes = async (preferredCurrencyCode: string, sessionData?: any) => {
    const queryData = await getQueryData(sessionData);

    if (preferredCurrencyCode) {
      queryData.preferredCurrencyCode = preferredCurrencyCode;
    }

    setIsLoading(true);
    setError('');

    return await getQuotes({
      params: { ...queryData, destination: queryData?.destination?.address },
    })
      .then(({ data, errors }: any) => {
        if (errors || !data.quotes.length) {
          setError('noPrice');
        }
        setQuotes(data.quotes);
        return data;
      })
      .catch((e: any) => {
        console.log('Error while requesting quotes...', e);
        throw (e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return [quotes, isLoading, error, retrieveQuotes, setError];
};
